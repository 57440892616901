import { useRef, useCallback, useContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { DAppContext } from "./context";
import Logo from "./assets/logo.svg";
import EtherscanIcon from './assets/etherscan-logo-light-circle.svg';
import TwitterIcon from "./assets/twitter.svg";
import OpenseaIcon from "./assets/opensea.svg";
import GSDLogo from "./assets/gsd_logo.svg";
import GoblinLizaImg from "./assets/goblin_liza.png";
import DuckImg from "./assets/duck.png";
import CoinsImg from "./assets/coins.png";
import "./App.css";

const ConnectContainer = ({ hasUserData, connectBrowserWallet, handleCounter, count, handleCustomMint }) => {
  if (hasUserData) {
    return (
      <div className="form-counter-container">
        <div className="form-counter">
          <button
            onClick={handleCounter}
            type="button"
            className="counter-btn"
            data-pos="minus"
          >
            -
          </button>
          <span> {count} </span>
          <button
            onClick={handleCounter}
            type="button"
            className="counter-btn"
            data-pos="plus"
          >
            +
          </button>
          <button
            onClick={handleCustomMint}
            type="button"
            className="connect-button mint-button"
          >
            Free Mint
          </button>
        </div>
        <h5>Max 3 NFTs per wallet</h5>
      </div>
    );
  }

  return (
    <button className="connect-button connect-wallet-button">
      Minted out
    </button>
  );
};

const App = () => {
  const infoRef = useRef(null);
  const handleScrollClick = useCallback(
    () => infoRef?.current?.scrollIntoView({ behavior: "smooth" }),
    [infoRef]
  );
  const [count, setCount] = useState(1);
  const [mintedCount, setMintedCount] = useState(0);
  const { connectBrowserWallet, mint, userData, contractDetails } =
    useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count + 1 <= 3) {
      setCount(() => count + 1);
      return;
    }

    if (pos === "minus" && count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);
  return (
    <>
      <header>
        <video autoPlay muted loop>
          <source src="video_bg.mp4" type="video/mp4" />
        </video>
        <div className="overlay">
          <div className="header-meta">
            <img src={Logo} alt="unreal goblins" />
            <div>
              <div className="socials">
                <a
                  href="https://twitter.com/unrealgoblins"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TwitterIcon} alt="twitter" />
                </a>
                <a 
                  href="https://opensea.io/collection/unreal-goblins" 
                  target="_blank" 
                  rel="noreferrer"
                >
                  <img src={OpenseaIcon} alt="opensea" />
                </a>
                <a
                  href="https://etherscan.io/address/0xc94f2ad944c0c2f456d051010d5f0e69a4a33043"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={EtherscanIcon} alt="etherscan" />
                </a>
              </div>
              <div className="connect-container">
                <ConnectContainer 
                  hasUserData={!!userData}
                  connectBrowserWallet={connectBrowserWallet}
                  handleCounter={handleCounter}
                  count={count}
                  handleCustomMint={handleCustomMint}
                />
              </div>
            </div>
          </div>
          <div className="heading">
            <h1>First 3D Animated NFT Collection</h1>
            <h1 className="text-green">
              fully generated and rendered on Unreal Engine
            </h1>
          </div>
          <h3>Full free mint</h3>
          <div className="scroll-arrow" onClick={handleScrollClick}>
            &#10225;
          </div>
        </div>
      </header>
      <div ref={infoRef} className="info">
        <div className="connect-container">
          <ConnectContainer 
            hasUserData={!!userData}
            connectBrowserWallet={connectBrowserWallet}
            handleCounter={handleCounter}
            count={count}
            handleCustomMint={handleCustomMint}
          />
        </div>
        <div className="sections">
          <section>
            <img src={DuckImg} alt="goblin duck" />
            <div>
              <h4>About the Project</h4>
              <h2><span className='text-green'>Goblins?</span> Again?!</h2>
              <span>Everything #fortheculture, gsd.club following the Goblin Town trend, with the awesome team behind we'll show how we see real or unreal Goblin Town. So be it, gsd.club wants to present NFT space quality art so meet please Unreal Goblins 3D Animated NFT collection rendered and generated using Unreal Engine.</span>
            </div>
          </section>
          <section>
            <div>
              <h2>
                Perfect Art + Unbreakable Smart Contract with the lowest GAS ={" "}
                <span className="text-green">GSD.Club Way</span>
              </h2>
              <p>
                So we are very excited to announce the first of its kind 3D
                Animated NFT Collection fully created with Unreal Engine -
                Unreal Goblins!
              </p>
              <p>
                This is impossible,you&apos;d say? Haha, of course no, not for
                us! So we&apos;ve decided to take a grip of the field and make
                it in the extremely short timeline - in 2 weeks! Oh yes, we do
                love challenges!
                <br />
                Check out our Twitter to see the whole journey.
              </p>
              <p>
                We are very grateful to the Unreal Engine Team for giving us an
                opportunity to use their software for free and bring our idea to
                life!
              </p>
              <h2>
                Doing it #fortheculture <br />
                <span className="text-green">we are just showing off!</span>
              </h2>
              <p>
                721 UGs reserved for the team bcs they are awesome
              </p>
            </div>
            <img src={GoblinLizaImg} alt="goblin liza" />
          </section>
          <section>
            <img src={CoinsImg} alt="goblin coins" />
            <div>
              <h2>
                With love and gratitude!
                <br />
                <span className="text-green">Your GSD.Club team!</span>
              </h2>
              <div className="links">
                <a
                  href="https://gsd.club/"
                  className="site-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  visit our website
                </a>
                <img src={GSDLogo} alt="gsd club" />
              </div>
            </div>
          </section>
        </div>
        <h4 className="copyright">Unreal Goblins © 2022</h4>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default App;
